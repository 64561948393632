<template>
  <div class="w-lg-100 bg-white p-10 p-lg-12 mx-auto auth-forms mb-20">
    <div class="bank-register">
      <img v-if="currentStep === 2" src="/media/misc/step-2.svg" class="bank-register__step" alt="step">
      <img v-if="currentStep === 3" src="/media/misc/step-3.svg" class="bank-register__step" alt="step">

      <div class="bank-register__title">
        <a href="" v-if="currentStep === 2" @click.prevent="currentStep --">
          <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M0.22 6.7179C0.0793078 6.57738 0.000175052 6.38674 0 6.1879V5.8079C0.00230401 5.60949 0.0811163 5.41962 0.22 5.2779L5.36 0.147899C5.40648 0.101035 5.46178 0.0638379 5.52271 0.0384536C5.58364 0.0130692 5.64899 0 5.715 0C5.78101 0 5.84636 0.0130692 5.90729 0.0384536C5.96822 0.0638379 6.02352 0.101035 6.07 0.147899L6.78 0.857899C6.82656 0.903522 6.86356 0.957978 6.88881 1.01808C6.91406 1.07818 6.92707 1.14271 6.92707 1.2079C6.92707 1.27309 6.91406 1.33762 6.88881 1.39772C6.86356 1.45782 6.82656 1.51228 6.78 1.5579L2.33 5.9979L6.78 10.4379C6.82686 10.4844 6.86406 10.5397 6.88945 10.6006C6.91483 10.6615 6.9279 10.7269 6.9279 10.7929C6.9279 10.8589 6.91483 10.9243 6.88945 10.9852C6.86406 11.0461 6.82686 11.1014 6.78 11.1479L6.07 11.8479C6.02352 11.8948 5.96822 11.932 5.90729 11.9573C5.84636 11.9827 5.78101 11.9958 5.715 11.9958C5.64899 11.9958 5.58364 11.9827 5.52271 11.9573C5.46178 11.932 5.40648 11.8948 5.36 11.8479L0.22 6.7179Z"
              fill="black" />
          </svg>
        </a>
        <span v-if="currentStep === 1">{{ $t("Create New Password") }}</span>
        <span v-if="currentStep === 2">{{ $t("Personal Details") }}</span>
        <span v-if="currentStep === 3">{{ $t("Bank Details") }}</span>
      </div>

      <el-form
        ref="firstSection"
        :model="formData.firstSection"
        :rules="firstSection"
        status-icon
        class="bank-register__form"
        v-if="currentStep === 1"
      >
        <el-form-item prop="password">
          <label class="form-label">{{ $t("New Password") }}</label>
          <el-input type="password" v-model="formData.firstSection.password"></el-input>
        </el-form-item>
        <el-form-item prop="confirmPassword">
          <label class="form-label">{{ $t("Confirm New Password") }}</label>
          <el-input type="password" v-model="formData.firstSection.confirmPassword"></el-input>
        </el-form-item>
        <div class="w-100 d-flex justify-content-center">
          <button
            type="submit"
            ref="submitButton"
            class="btn btn-lg main-btn w-100 mb-5 mt-10 d-block m-auto"
            @click.prevent="confirmPasswordChange"
          >
            <span class="indicator-label">
              {{ $t("Personal Details") }}
              <img src="/media/misc/arrow-right-white.svg" class="ms-2" alt="">
            </span>

            <span class="indicator-progress">
            {{ $t("pleaseWait") }}
            <span
              class="spinner-border spinner-border-sm align-middle ms-2"
            ></span>
          </span>
          </button>
        </div>
      </el-form>

      <el-form
        ref="secondSection"
        :model="formData.secondSection"
        :rules="secondSection"
        status-icon
        class="bank-register__form"
        v-if="currentStep === 2"
      >
        <div class="row">
          <div class="col-6">
            <el-form-item prop="first_name">
              <label class="form-label">{{ $t("First Name") }}</label>
              <el-input v-model="formData.secondSection.first_name"></el-input>
            </el-form-item>
          </div>
          <div class="col-6">
            <el-form-item prop="last_name">
              <label class="form-label">{{ $t("Last Name") }}</label>
              <el-input v-model="formData.secondSection.last_name"></el-input>
            </el-form-item>
          </div>
        </div>
        <el-form-item prop="job_title">
          <label class="form-label">{{ $t("Job Title") }}</label>
          <el-input v-model="formData.secondSection.job_title"></el-input>
        </el-form-item>
        <el-form-item prop="country" class="filterable-select">
          <el-select
            v-model="formData.secondSection.country"
            :placeholder="$t('Country of Residence')"
            filterable
          >
            <el-option
              v-for="(item,index) in countries"
              :key="index"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <div class="w-100 d-flex justify-content-center">
          <button
            type="submit"
            ref="submitButton"
            class="btn btn-lg main-btn w-100 mb-5 mt-10 d-block m-auto"
            @click.prevent="bankUserSetup"
          >
            <span class="indicator-label">
              {{ $t("Bank Details") }}
            </span>

            <span class="indicator-progress">
            {{ $t("pleaseWait") }}
            <span
              class="spinner-border spinner-border-sm align-middle ms-2"
            ></span>
          </span>
          </button>
        </div>
      </el-form>
      <el-form
        ref="thirdSection"
        :model="formData.thirdSection"
        :rules="thirdSection"
        status-icon
        class="bank-register__form"
        v-if="currentStep === 3"
      >
        <el-form-item prop="followed_product_areas" class="hide-placeholder mb-15">
          <label>{{ $t("Follow Product Areas") }}</label>
          <el-select
            v-model="formData.thirdSection.followed_product_areas"
            multiple
            collapse-tags
          >
            <el-option
              v-for="(item,index) in followedProductAreas"
              :key="index"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="followed_countries" class="hide-placeholder">
          <label>{{ $t("Follow Bank Country") }}</label>
          <el-select
            v-model="formData.thirdSection.followed_countries"
            multiple
            collapse-tags
          >
            <el-option
              v-for="(item,index) in followedCountries"
              :key="index"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <div class="w-100 d-flex justify-content-center">
          <button
            type="submit"
            ref="submitButton"
            class="btn btn-lg main-btn w-100 mb-5 mt-10 d-block m-auto"
            @click="userFollowedUpdate"
          >
            <span class="indicator-label">
              {{ $t("Get Started") }}
            </span>

            <span class="indicator-progress">
            {{ $t("pleaseWait") }}
            <span
              class="spinner-border spinner-border-sm align-middle ms-2"
            ></span>
          </span>
          </button>
        </div>
        <div class="w-100 d-flex justify-content-center">
          <span class="info-text">{{ $t('You can make any changes later as well') }}</span>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import { StaticDataEnum } from "@/store/enums/StaticDataEnum";
import { diagLog } from "@/core/helpers/GlobalHelper";
import BankFollowType from "@/store/enums/bank/BankFollowType";
import BankFollowOperationEnum from "@/store/enums/bank/BankFollowOperationEnum";
import { DataService } from "@/core/services/DataService";
import { Actions } from "@/store/enums/StoreEnums";

export default {
  name: "BankPersonalInfoSetup",
  data() {
    let validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("Please input Password"));
      } else {
        if (this.formData.firstSection.confirmPassword !== "") {
          this.$refs.firstSection.validateField("confirmPassword");
        }
        callback();
      }
    };
    let validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("Please input Confirm Password"));
      } else if (value !== this.formData.firstSection.password) {
        callback(new Error("Passwords not match"));
      } else {
        callback();
      }
    };
    return {
      followedProductAreas: [],
      followedCountries: [],
      buttonIndicator: "off",
      currentStep: 1,
      formData: {
        firstSection: {
          password: "",
          confirmPassword: ""
        },
        secondSection: {
          first_name: "",
          last_name: "",
          job_title: "",
          country: ""
        },
        thirdSection: {
          followed_countries: [],
          followed_product_areas: []
        }
      },
      firstSection: {
        password: [
          { required: true, message: this.$t("Please input Password"), trigger: "change" },
          { validator: validatePass, trigger: "change" }
        ],
        confirmPassword: [
          { required: true, message: this.$t("Please input Confirm Password"), trigger: "change" },
          { validator: validatePass2, trigger: "change" }
        ]
      },
      secondSection: {
        first_name: [
          { required: true, message: this.$t("Please input First name"), trigger: "blur" }
        ],
        last_name: [
          { required: true, message: this.$t("Please input Last name"), trigger: "blur" }
        ],
        job_title: [
          { required: true, message: this.$t("Please input Job Title"), trigger: "blur" }
        ],
        country: [
          { required: true, message: this.$t("Please select Country of Residence*"), trigger: "blur" }
        ]
      },
      thirdSection: {
        followed_countries: [
          { required: true, message: this.$t("Please select Country"), trigger: "change" }
        ],
        followed_product_areas: [
          { required: true, message: this.$t("Please select Product Area"), trigger: "change" }
        ]
      }
    };
  },
  computed: {
    countries() {
      return store.getters.allCountries;
    },
    authUser() {
      return store.getters.currentUser;
    }
  },
  mounted() {
    store.dispatch("fetchStaticData", StaticDataEnum.COUNTRIES);
    this.fetchBankData();
  },
  methods: {
    async fetchBankData() {
      let { countries, productAreas } = await store.dispatch("fetchBankData");
      if (countries) {
        this.followedCountries = countries;
        this.formData.thirdSection.followed_countries = countries;
      }
      if (productAreas) {
        this.followedProductAreas = productAreas;
        this.formData.thirdSection.followed_product_areas = productAreas;
      }
    },
    confirmPasswordChange() {
      this.$refs.firstSection.validate(valid => {
        if (valid) {
          this.currentStep++;
        }
      });
    },
    bankUserSetup() {
      this.currentStep++;
      this.$refs.secondSection.validate(valid => {
        if (valid) {
          let submitButton = this.$refs.submitButton;
          submitButton.disabled = true;
          submitButton.setAttribute("data-kt-indicator", "on");

          store.dispatch("bankUserSetup", {
            ...this.formData.secondSection,
            password: this.formData.firstSection.password
          })
            .then(() => {
              this.currentStep = 3;
              this.authUser.selfUpdate({ is_setup_finished: true });
              DataService.storeAuthUserData(this.authUser);
            })
            .finally(() => {
              submitButton.removeAttribute("data-kt-indicator");
              submitButton.disabled = false;
            });
        }
      });
    },

    async userFollowedUpdate(e) {
      e.preventDefault();
      this.$refs.thirdSection.validate(async valid => {
        if (valid) {
          let submitButton = this.$refs.submitButton;
          submitButton.disabled = true;
          submitButton.setAttribute("data-kt-indicator", "on");

          try {
            await store.dispatch("updateBankUserFollows", {
              type: BankFollowType.COUNTRY,
              operation: BankFollowOperationEnum.ADD,
              items: this.formData.thirdSection.followed_countries
            });
            await store.dispatch("updateBankUserFollows", {
              type: BankFollowType.PRODUCT_AREA,
              operation: BankFollowOperationEnum.ADD,
              items: this.formData.thirdSection.followed_product_areas
            }).finally(() => {
              submitButton.removeAttribute("data-kt-indicator");
              submitButton.disabled = false;
            });
            window.location.href = "/dashboard";
          } catch (e) {
            diagLog("catch err => ", e);
            await store.dispatch(Actions.LOGOUT);
          }
        }
      });

    }
  }
};
</script>

<style lang="scss">
.bank-register__form {
  .info-text {
    margin-top: 13px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #434343;
    opacity: 0.6;
  }
}
</style>
